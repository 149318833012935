import React, { useState } from 'react'
import { useRouter } from 'next/router'
import Icon from 'components/elements/Icon'
import { useCookies } from 'react-cookie'

import environment from 'environment'

import { DeckCardInfoType } from 'types/search'
import { DEFAULT_DECK_CARD_IMAGE } from 'types/deck'
import { ROLES } from 'types/user'

import { useAppSelector } from 'redux/hooks'

import DeckService from 'services/deck.service'
import ToastService from 'services/toast.service'
import CookieService from 'services/cookie.service'

import ArchidektDropdown from 'components/elements/ArchidektDropdown'
import Link from 'components/elements/Link'
import SimpleSpinner from 'components/elements/SimpleSpinner'

import { PUBLIC_ROUTES } from 'utils/token.utils'
import { encodeUsernameRoute } from 'utils/encoding'
import { generateDeckUrl } from 'utils/deckSeo'

import styles from './accountDropdown.module.scss'

const AccountDropdown = () => {
  const router = useRouter()

  const [_confirmSignOut, setConfirmSignOut] = useState(false)

  const [loading, setLoading] = useState(false)
  const [userDecks, setUserDecks] = useState(new Array<DeckCardInfoType>())
  const [userDecksFetched, setUserDecksFetched] = useState(false)

  // prettier-ignore
  const [{ tbId: userId, tbUser: username, tbRootFolder: rootFolder, tbR: roles }] = useCookies(['tbId', 'tbUser', 'tbRootFolder', 'tbR'])

  const saving = useAppSelector(state => state.deck.saving)

  const handleSignOut = () => {
    if (saving !== 'saved') return setConfirmSignOut(true)

    CookieService.logout()

    if (!router.pathname.includes('account')) router.reload()
    else router.push('/')
  }

  const handleFetchUserDecks = () => {
    if (userDecksFetched) return

    setLoading(true)

    DeckService.getMyDecks()
      .then(res => {
        setUserDecks(res.results)
        setUserDecksFetched(true)
      })
      .catch(ToastService.create)
      .finally(() => setLoading(false))
  }

  const isMod = roles.includes(ROLES.MOD)
  const hideOnNonPublicRoutes = environment.runningOnPrivateServer && PUBLIC_ROUTES.includes(router.pathname)

  return (
    <ArchidektDropdown
      onFocus={handleFetchUserDecks}
      menuClassName={styles.dropdownMenu}
      options={[
        { label: 'New deck', icon: 'add', to: '/new-deck', hidden: hideOnNonPublicRoutes },
        { label: 'New card package', icon: 'cube', to: '/new-package', hidden: hideOnNonPublicRoutes },
        { label: 'Deck sandbox', icon: 'flask', to: '/sandbox', hidden: hideOnNonPublicRoutes },
        { type: 'spacer', hidden: hideOnNonPublicRoutes },
        { label: 'My folders', icon: 'folder outline', to: `/folders/${rootFolder}/`, hidden: hideOnNonPublicRoutes },
        {
          label: 'My decks',
          icon: 'user',
          to: `/search/decks/?owner=${username}&ownerexact=true`,
          hidden: hideOnNonPublicRoutes,
        },
        {
          label: 'My card packages',
          icon: 'cubes',
          to: `/search/decks/?owner=${username}&ownerexact=true&packages=true`,
          hidden: hideOnNonPublicRoutes,
        },
        {
          label: 'My collection',
          icon: 'address book outline',
          to: `/collection/v2/${userId}/`,
          hidden: hideOnNonPublicRoutes,
        },
        {
          label: 'My global card categories',
          to: '/global-categories',
          icon: 'globe',
          hidden: hideOnNonPublicRoutes,
        },
        { type: 'spacer', hidden: hideOnNonPublicRoutes },
        {
          type: 'custom',
          hidden: !loading,
          customChild: (
            <div className={styles.decksLoading}>
              <SimpleSpinner size="xSmall" />
              Fetching your decks...
            </div>
          ),
        },
        {
          type: 'spacer',
          hidden: !loading,
        },
        {
          type: 'custom',
          hidden: hideOnNonPublicRoutes && !userDecksFetched,
          className: styles.deckList,
          customChild: (
            <>
              {userDecks.map(deck => (
                <Link key={deck.id} href={generateDeckUrl(deck.id, deck.name)}>
                  <img
                    alt={deck.name}
                    className={styles.previewImage}
                    src={deck.customFeatured || deck.featured || DEFAULT_DECK_CARD_IMAGE}
                  />
                  <span>{deck.name}</span>
                </Link>
              ))}
            </>
          ),
        },
        { type: 'spacer', hidden: hideOnNonPublicRoutes || userDecks.length === 0 },
        { label: 'My user', icon: 'user', to: `/u/${encodeUsernameRoute(username)}`, hidden: hideOnNonPublicRoutes },
        { label: 'Account settings', icon: 'cog', to: '/accountSettings/site-settings', hidden: hideOnNonPublicRoutes },
        {
          label: 'Moderator settings',
          icon: 'superscript',
          to: '/moderation',
          className: styles.mod,
          hidden: !isMod,
        },
        { label: 'Sign out', icon: 'sign out', onClick: handleSignOut },
      ]}>
      <button className={styles.dropdownTrigger}>
        <span>
          <Icon name="user" />
        </span>
        <span className={styles.username}>{username}</span>
      </button>
    </ArchidektDropdown>
  )
}

export default AccountDropdown
