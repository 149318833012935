var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"HxA0Bq0Q7uiGXdHVy41oA"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// For testing, disable allowUrls and set debug: true
// In next.config.js, `const defaultExport = isLocalServer` -> `const defaultExport = false`

import * as Sentry from '@sentry/nextjs'

const APP_URL_MATCHER = /^https?:\/\/[^/]*(archidekt|topdekt)\.com/i
const DISALLOW_USER_AGENTS_MATCHING = [
  /crawler/i,
  /bot/i, // Could bite us
]

Sentry.init({
  dsn: 'https://637ab885d3ba4dc0b28ef6db5753b336@o4505983004114944.ingest.sentry.io/4505983054118912',

  // only source URLs which error and match this pattern go to Sentry
  allowUrls: [APP_URL_MATCHER],

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // rate to capture replay when an error occurs
  replaysOnErrorSampleRate: 0.1,

  // rate to capture a replay when an error does not occur
  replaysSessionSampleRate: 0,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: true,
    }),
  ],

  beforeBreadcrumb(breadcrumb, hint) {
    if (
      breadcrumb.type === 'http' &&
      breadcrumb.data &&
      breadcrumb.data.url &&
      !breadcrumb.data.url.match(APP_URL_MATCHER)
    ) {
      return null
    }

    return breadcrumb
  },

  beforeSend(event) {
    const frames = event?.exception?.values?.[0]?.stacktrace?.frames || []

    // ignore if no frames or last frame lacks context_line
    // 3rd party / ads scripts should lack the context line
    if (!frames[frames.length - 1]?.context_line) return null

    // ignore bot and crawler user agents since they frequently encounter errors that real users do not
    const hasDisallowedUA = DISALLOW_USER_AGENTS_MATCHING.some(ua => window?.navigator?.userAgent?.match?.(ua))
    if (hasDisallowedUA) return null

    return event
  },
})
